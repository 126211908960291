<template>
    <div> 

        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes" id="table_Transparence">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody id="tbody_Transparence"> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <template v-for="(item, key) in data.data.data" >
                            <tr v-for="(sub_item, sub_key) in max_row(item)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)" >   
                                <td>
                                    <template v-if="sub_key===0">
                                        <label class="table-check">
                                            <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                            <span class="checkbox-table"></span>
                                        </label>
                                    </template>
                                </td>
                                <td>
                                    <template v-if="sub_key===0">
                                    <router-link :to="'/detail/'+item.drug_id+'/trs'" target="_blank" rel="noopener">
                                        <div class="icon-fiche"></div>
                                    </router-link>
                                    <div v-if="isAdmin()">
                                        <a :href="modifyPage('transparences', item.drug_id)" target="_blank" rel="noopener"> 
                                            <em class="icon-edit"></em>
                                        </a>
                                    </div>
                                    </template>
                                </td>
                                <template v-for="(column) in data.column" :key="column.code" >
                                    <template v-if="item && item.transparence">
                                        <td v-if="column.Status && column.code == 'tc_001'" :code="column.code">
                                            <span v-html="ema_id(item.links)" v-if="sub_key===0"></span>                                
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_002'" :code="column.code">
                                            <template v-if="sub_key===0">{{item.drug_id}}</template>
                                        </td>
                                        <!-- <td v-else-if="column.Status && column.code == 'tc_003'" :code="column.code">
                                            <span v-html="icon_link(item.drug_id, 'trs')"  v-if="sub_key===0"></span>
                                        </td> -->
                                        <td v-else-if="column.Status && column.code == 'tc_004'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty(item.category)}}</template>
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_005'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty(item.dci_new)}}</template>                               
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_006'" :code="column.code">
                                            <div v-html="rembursement_status_all_type(item)" v-if="sub_key===0"></div>
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_074'" :code="column.code">
                                            <span v-if="sub_key===0"  v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_007'" :code="column.code">
                                            <span v-html="column_status(item)" v-if="sub_key===0"></span>
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_008'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty(DDMMMYY(item.ma_date))}} </template>    
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_009'" :code="column.code">
                                            <template v-if="sub_key===0">{{ check_empty(DDMMMYY(item.transparence.trs_date_depot_ct))}}</template>   
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_010'" :code="column.code">
                                            <template v-if="sub_key===0">{{ check_empty(DDMMMYY(item.transparence.trs_ordre_du_jour_date))}}</template>   
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_011'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty(DDMMMYY(item.decision_date))}}</template>   
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_012'" :code="column.code">                                
                                        <span v-html="datediff(item.transparence.trs_date_type_amm, item.decision_date)" v-if="sub_key===0"></span>
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_013'" :code="column.code">                                
                                            <template v-if="sub_key===0">{{check_empty(item.name)}}</template>   
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_014'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty(item.dci)}}</template>   
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_015'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty(item.firm)}}</template>   
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_016'" :code="column.code">
                                            <span v-if="sub_key===0" v-html="check_empty(array(item.atc))"></span>
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_017'" :code="column.code">
                                            <span v-if="sub_key===0" v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_018'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty_with_locale(item.transparence.trs_type_demandes,'transparence.trs_type_demandes.')}}</template>   
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_019'" :code="column.code">                                
                                            <template v-if="sub_key===0"> 
                                                <template v-if="$i18n.locale !== 'fr'"> 
                                                    <span v-html="check_empty(array(item.classification_en))"></span>
                                                </template>
                                                <template v-else>  
                                                    <span v-html="check_empty(array(item.classification_fr))"></span>
                                                </template> 
                                            </template>
                                            
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_020'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty(item.transparence.trs_motif)}} </template>                               
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_021'" :code="column.code">
                                            {{item.transparence.transparence_smr[sub_key] ? check_empty(item.transparence.transparence_smr[sub_key]['smr_id']) : '-'}} 
                                        </td> 
                                        <td v-else-if="column.Status && column.code == 'tc_022'" :code="column.code">   
                                            <template v-if="item.transparence && item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key]">
                                                <template v-if="$i18n.locale !== 'fr'"> 
                                                    <Readmore :longText="item.transparence.transparence_smr[sub_key]['smr_title_en']" textAlign="center"/>
                                                </template>
                                                <template v-else>  
                                                    <Readmore :longText="item.transparence.transparence_smr[sub_key]['smr_title']" textAlign="center"/>
                                                </template> 
                                            </template> 
                                            <span v-else> - </span>
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_023'" :code="column.code">
                                            <template v-if="item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key]">
                                                <div v-html="smr_type(item.transparence.transparence_smr[sub_key]['smr_type'])"></div> 
                                                <span v-if="item.transparence.transparence_smr[sub_key]['smr_remboursement_non_demand'] && item.transparence.transparence_smr[sub_key]['smr_type']!='Remboursement non sollicité par la firme'">
                                                    {{$t('transparence.smr_remboursement_non_demand')}}
                                                </span> 
                                            </template> 
                                            <span v-else> - </span>            
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_024'" :code="column.code">        
                                            <Readmore v-if="item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key]"
                                                    :longText="treatment_line(item.transparence.transparence_smr[sub_key]['treatment_line']) + 
                                                    (item.transparence.transparence_smr[sub_key]['treatment_specificity'] ? '<p>'+item.transparence.transparence_smr[sub_key]['treatment_specificity']+'</p>' : '')  +
                                                    (item.transparence.transparence_smr[sub_key]['treatment_administration'] ? '<p>'+item.transparence.transparence_smr[sub_key]['treatment_administration']+'</p>' : '')" textAlign="center"/>
                                            <span v-else> - </span>
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_025'" :code="column.code">
                                            <Readmore v-if="item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key] && $i18n.locale !== 'fr'" :longText="item.transparence.transparence_smr[sub_key]['smr_text_en']" />
                                            <Readmore v-else-if="item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key] && $i18n.locale === 'fr'" :longText="item.transparence.transparence_smr[sub_key]['smr_text']" />
                                            <span v-else> - </span>
                                        </td>
                                        <td v-else-if="column.Status && column.code == 'tc_026'" :code="column.code">                                                                
                                            {{item.transparence.transparence_smr[sub_key] ? check_empty(item.transparence.transparence_smr[sub_key]['asmr_id']) : '-'}}
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_028'" :code="column.code">  
                                            <template v-if="item.transparence.transparence_smr[sub_key]" > 
                                                <Readmore v-if="$i18n.locale !== 'fr'" :longText="item.transparence.transparence_smr[sub_key]['asmr_title_en']" textAlign="center" />
                                                <Readmore v-else :longText="item.transparence.transparence_smr[sub_key]['asmr_title']" textAlign="center" />
                                            </template>                               
                                            <span v-else> - </span>
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_029'" :code="column.code">                                                                
                                            {{item.transparence.transparence_smr[sub_key] ? check_empty_with_locale(item.transparence.transparence_smr[sub_key]['asmr_obtenue'], 'transparence.asmr.') : '-'}} 
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_030'" :code="column.code">                                
                                            {{item.transparence.transparence_smr[sub_key] ? check_empty(item.transparence.transparence_smr[sub_key]['asmr_demande']) : '-'}}
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_031'" :code="column.code">
                                            <template v-if="item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key]">
                                                <Readmore v-if="$i18n.locale !== 'fr'" :longText="item.transparence.transparence_smr[sub_key]['asmr_text_en']"/>
                                                <Readmore v-else :longText="item.transparence.transparence_smr[sub_key]['asmr_text']"/>
                                            </template>
                                            <span v-else> - </span>
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_032'" :code="column.code">   
                                            <template v-if="item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key]">
                                                <span v-if="$i18n.locale !== 'fr'" v-html="json_fields(item.transparence.transparence_smr[sub_key]['nombre_de_patient_json'], 'NDP_text_en')" textAlign="center"></span> 
                                                <span v-else v-html="json_fields(item.transparence.transparence_smr[sub_key]['nombre_de_patient_json'], 'NDP_text')" textAlign="center"></span> 
                                            </template> 
                                            <span v-else> - </span>
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_033'" :code="column.code">
                                            <template v-if="item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key]">
                                                <span v-html="json_fields(item.transparence.transparence_smr[sub_key]['isp_json'], 'ISP_libelle')"></span>    
                                            </template>    
                                            <span v-else> - </span>
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_034'" :code="column.code">                                    
                                            <span v-html="show_text(item.transparence.trs_epi,'yes')"  v-if="sub_key===0"></span>                                    
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_035'" :code="column.code">                                    
                                            <span v-html="show_text(item.transparence.trs_epi_dep,'yes')"  v-if="sub_key===0"></span>                                    
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_036'" :code="column.code">
                                            <template v-if="item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key]">
                                                <span v-html="json_fields(item.transparence.transparence_smr[sub_key]['besoin_medical_json'], 'besoin_medical_select')"></span>
                                            </template>     
                                            <span v-else> - </span>                         
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_037'" :code="column.code">                                    
                                            <span v-html="show_text(item.transparence.trs_avis_ct,item.transparence.trs_avis_ct)"  v-if="sub_key===0"></span>                                                                    
                                        </td>                                                             
                                        <td v-else-if="column.Status && column.code == 'tc_038'" :code="column.code">                               
                                        <template v-if="sub_key===0"> <span v-html="hta_process_status_3(item)"></span></template>                            
                                        </td>                        
                                        <template v-else-if="column.Status && column.code == 'tc_039'">   
                                            <!-- Limitations -->
                                            <td  :key="column.code+'_040'" code="tc_040">     
                                                <template v-if="sub_key===0">
                                                    <Readmore v-if="$i18n.locale !== 'fr'" :longText="item.target_population_en"/>
                                                    <Readmore v-else :longText="item.target_population" />  
                                                </template>  
                                            </td>   
                                            <td  :key="column.code+'_041'" code="tc_041">    
                                                <template v-if="sub_key===0">
                                                    <Readmore v-if="$i18n.locale !== 'fr'" :longText="item.prescription_and_delivery_conditions_en" />
                                                    <Readmore v-else :longText="item.prescription_and_delivery_conditions" />
                                                </template>                                                             
                                            </td>   
                                            <td  :key="column.code+'_042'" code="tc_042">  
                                                <template v-if="sub_key===0">
                                                    <Readmore v-if="$i18n.locale !== 'fr'" :longText="item.access_scheme_en" />
                                                    <Readmore v-else :longText="item.access_scheme" />
                                                </template> 
                                            </td>     
                                            <td  :key="column.code+'_043'" code="tc_043"> 
                                                <template v-if="sub_key===0">
                                                    <Readmore v-if="$i18n.locale !== 'fr'" :longText="item.outcome_level_en" />
                                                    <Readmore v-else :longText="item.outcome_level" />
                                                </template>                               
                                            </td>     
                                        </template>
                                        <!-- -- -->
                                        <td v-else-if="column.Status && column.code == 'tc_044'" :code="column.code">
                                            <template v-if="sub_key===0">
                                                <template v-if="$i18n.locale !== 'fr'"> 
                                                    <Readmore :longText="item.rationale_and_comments_html_en"/> 
                                                </template>
                                                <template v-else>  
                                                    <Readmore :longText="item.rationale_and_comments_html_fr"/> 
                                                </template>    
                                            </template>                  
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_045'" :code="column.code">
                                            <template v-if="sub_key===0">
                                                <template v-if="$i18n.locale !== 'fr'"> 
                                                    <Readmore :longText="item.indication_en"/>
                                                </template>
                                                <template v-else>  
                                                    <Readmore :longText="item.indication_fr"/>
                                                </template> 
                                            </template>                                        
                                        </td>     
                                        <td v-else-if="column.Status && column.code == 'tc_046'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty_with_locale(item.mk_orphelin)}}</template> 
                                        </td>    
                                        <td v-else-if="column.Status && column.code == 'tc_047'" :code="column.code">
                                            <template v-if="sub_key===0"> {{check_empty_with_locale(item.transparence.trs_type_amm, 'advance.TransparenceTC.type_amm.')}}</template> 
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_048'" :code="column.code">
                                            <span v-html="check_empty(treatment_line(item.treatment_line))" v-if="sub_key===0"></span>
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_049'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty(item.specificity) }}</template> 
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_050'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty(item.administration) }}</template> 
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_051'" :code="column.code">
                                            <span v-html="show_text(item.transparence.trs_mk_exception,'yes')" v-if="sub_key===0"></span>
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_052'" :code="column.code">     
                                            <template v-if="item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key] && item.transparence.transparence_smr[sub_key]['atu_france'] && item.transparence.transparence_smr[sub_key]['atu_france'].length">
                                                <span v-html="atu_motif(item.transparence.transparence_smr[sub_key]['atu_france'])"></span>
                                            </template>     
                                            <span v-else> - </span>
                                        </td>                                   
                                        <td v-else-if="column.Status && column.code == 'tc_053'" :code="column.code">
                                            <template v-if="indication_jo_info && indication_jo_info[item.drug_id] && indication_jo_info[item.drug_id][sub_key]">
                                                <span v-html="IndicationJoInfo(indication_jo_info[item.drug_id][sub_key], 'ss', item.transparence.trs_date)"></span>    
                                            </template> 
                                            <span v-else> - </span>
                                        </td>   
                                        <td v-else-if="column.Status && column.code == 'tc_054'" :code="column.code">
                                            <template v-if="indication_jo_info && indication_jo_info[item.drug_id] && indication_jo_info[item.drug_id][sub_key]">
                                                <span v-html="IndicationJoInfo(indication_jo_info[item.drug_id][sub_key], 'coll', item.transparence.trs_date)"></span>    
                                            </template>
                                            <span v-else> - </span>
                                        </td>  
                                        <td v-else-if="column.Status && column.code == 'tc_055'" :code="column.code">
                                            <template v-if="indication_jo_info && indication_jo_info[item.drug_id] && indication_jo_info[item.drug_id][sub_key]">
                                                <span v-html="IndicationJoInfo(indication_jo_info[item.drug_id][sub_key], 't2a', item.transparence.trs_date)"></span>    
                                            </template>
                                            <span v-else> - </span>
                                        </td>  
                                        <td v-else-if="column.Status && column.code == 'tc_056'" :code="column.code">
                                            <template v-if="indication_jo_info && indication_jo_info[item.drug_id] && indication_jo_info[item.drug_id][sub_key]">
                                                <span v-html="IndicationJoInfo(indication_jo_info[item.drug_id][sub_key], 'retro', item.transparence.trs_date)"></span>    
                                            </template> 
                                            <span v-else> - </span>
                                        </td>  
                                        <td v-else-if="column.Status && column.code == 'tc_075'" :code="column.code">
                                            <template v-if="indication_jo_info && indication_jo_info[item.drug_id] && indication_jo_info[item.drug_id][sub_key]">
                                                <span v-html="IndicationJoInfo(indication_jo_info[item.drug_id][sub_key], 'prise_en_charge_precoce', item.transparence.trs_date)"></span>    
                                            </template>
                                            <span v-else> - </span>
                                        </td> 
                                        <td v-else-if="column.Status && column.code == 'tc_057'" :code="column.code">
                                            <template v-if="sub_key===0 && item.link_rtu[0]">{{ item.link_rtu[0].avis_pour_prise}}</template>
                                            <span v-else> - </span>
                                        </td> 
                                        <td v-else-if="column.Status && column.code == 'tc_058'" :code="column.code">
                                            <template v-if="sub_key===0">{{ check_empty_with_locale(item.transparence.indirect_comparison_data)}}</template>
                                        </td> 
                                        <td v-else-if="column.Status && column.code == 'tc_059'" :code="column.code">
                                            <template v-if="sub_key===0">{{ check_empty_with_locale(item.transparence.acepted_ic)}}</template>
                                        </td> 
                                        <td v-else-if="column.Status && column.code == 'tc_060'" :code="column.code">                                    
                                            <Readmore :longText="item.transparence.population_ic" v-if="sub_key===0 && $i18n.locale == 'fr'" />
                                            <Readmore :longText="item.transparence.population_ic_en" v-if="sub_key===0 && $i18n.locale != 'fr'" />
                                        </td> 
                                        <td v-else-if="column.Status && column.code == 'tc_061'" :code="column.code">
                                            <span v-html="nct_number(item.nct_numbers)" v-if="sub_key===0" @click="drug_id = item.drug_id"></span>
                                        </td> 
                                        <td v-else-if="column.Status && column.code == 'tc_062'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty(item.transparence.trs_ref_avis_ct)}}</template>
                                        </td> 
                                        <td v-else-if="column.Status && column.code == 'tc_063'" :code="column.code">
                                            <span v-html="show_text(item.transparence.transparence_contribution_patient,'yes')" v-if="sub_key===0"></span>
                                        </td>   
                                        
                                        <td v-else-if="column.Status && column.code == 'tc_076'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty_with_locale(item.transparence.quality_of_life_data) }}</template>
                                        </td>  
                                        <td v-else-if="column.Status && column.code == 'tc_077'" :code="column.code">
                                            <template v-if="sub_key===0">
                                                <Readmore v-if="$i18n.locale === 'fr'" :longText="item.transparence.type_of_qol_data_presented"/>             
                                                <Readmore v-else :longText="item.transparence.type_of_qol_data_presented_en"/>
                                            </template>
                                        </td> 
                                        <td v-else-if="column.Status && column.code == 'tc_079'" :code="column.code">
                                            <template v-if="sub_key===0">{{check_empty_with_locale(item.transparence.accepted_qol) }}</template>
                                        </td>
                                        
                                        <template v-else-if="column.Status && column.code == 'tc_064'">                                   
                                            <td  :key="column.code+'_065'" code="tc_065" >
                                                <template v-if="sub_key===0">{{ check_empty(DDMMMYY(item.hta_process_date))}}</template>
                                            </td>     
                                            <td  :key="column.code+'_066'" code="tc_066" > 
                                                <!-- HTA decision / col 175                             -->
                                                <span v-if="sub_key===0" v-html="transparence_hta(item)"></span>
                                            </td>     
                                            <td  :key="column.code+'_067'" code="tc_067">                                    
                                                <template v-if="sub_key===0">{{ check_empty(DDMMMYY(item.rembursement_process_date)) }}</template>               
                                            </td>  
                                            <td  :key="column.code+'_068'" code="tc_068">                                                                      
                                                <span v-html="rembursement_status_all_type(item)" v-if="sub_key===0"></span>         
                                            </td>       
                                        </template>    
                                        <template v-else-if="column.Status && column.code == 'tc_069'">                                   
                                            <td  :key="column.code+'_070'" code="tc_070" >                                    
                                                <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="hta_process_status_2(item)" v-if="sub_key===0"></span>
                                            </td>     
                                            <td  :key="column.code+'_071'" code="tc_071" >                                      
                                                <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="rembursement_status_2(item)" v-if="sub_key===0"></span>                        
                                            </td>     
                                            <td  :key="column.code+'_072'" code="tc_072">    
                                                <span v-html="datediff(item.ma_date, item.rembursement_process_date)" v-if="sub_key===0"></span>
                                            </td>    
                                        </template>                           
                                        <td v-else-if="column.Status && column.code == 'tc_073'" :code="column.code">
                                            <Readmore v-if="sub_key===0 && $i18n.locale == 'fr'" :longText="item.transparence.data_package_fr" />
                                            <Readmore v-if="sub_key===0 && $i18n.locale != 'fr'" :longText="item.transparence.data_package_en" />
                                        </td>   
                                    </template>
                                </template>
                            </tr>
                        </template>

                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>
                    
                </tbody>
            </table>
        </div> 
        
        <ModalHTAstatus :item="item_hta_status" />
        <ModalEssaisClinique :id="drug_id" agency="Transparence" />      
    </div>
</template> 

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import Readmore from '@/components/elements/readmore.vue';
import ModalHTAstatus from '@/components/result/modal_hta_status.vue'
import { isAdmin, smr_type, column_status, hta_process_status_3, hta_process_status_2,  
        rembursement_status_2,rembursement_status_all_type,IndicationJoInfo, overview_regulatory_status, 
        modifyPage, ema_id, icon_link, DDMMMYY, check_empty, nct_number, treatment_line, datediff, array, 
        color_type_limitation_2, odd_even_border, check_empty_with_locale } from '@/utils'
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "Transparence",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalHTAstatus,
        ModalEssaisClinique
    },
    data : function() {
        return { 
            item_hta_status : {},
            drug_id : 0 //for essais clinique popup
        }
    },
     computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        },
        indication_jo_info(){
            let result = {}
            if(this.data.data.data && this.data.data.data.length > 0){
                this.data.data.data.forEach((val) => {
                    //console.log(val)
                    result[val.drug_id] = []
                    let check_jo_info = []                    
                    if(val.transparence && val.transparence.transparence_smr){                        
                        val.transparence.transparence_smr.forEach((smr) => {
                            let tempArr = []
                            if(val.transparence.indication_jo_info){
                                val.transparence.indication_jo_info.forEach((jo_info) => {
                                    if(jo_info.list_smr_id && jo_info.list_smr_id.includes(smr.smr_id)){
                                        check_jo_info.push(jo_info.id);
                                        tempArr.push(jo_info)
                                    }
                                })
                            }
                            result[val.drug_id].push(tempArr)
                        })
                    }
                    if(val.transparence && val.transparence.indication_jo_info){
                        val.transparence.indication_jo_info.forEach((jo_info) => {
                            if(!check_jo_info.includes(jo_info.id)){                                
                                result[val.drug_id].push([jo_info]);
                            }
                        })
                    }                   
                })
            }            
            return result
        }
    },
    methods: {
        overview_regulatory_status,
        rembursement_status_all_type,
        rembursement_status_2,
        hta_process_status_3,
        hta_process_status_2,  
        column_status,  
        IndicationJoInfo,   
        smr_type,
        isAdmin,
        ema_id, 
        icon_link, 
        DDMMMYY, 
        check_empty, 
        nct_number, 
        treatment_line, 
        datediff, 
        array, 
        color_type_limitation_2,
        odd_even_border,
        check_empty_with_locale,
        hta_status_modal(item){
            this.item_hta_status = item
        },
        show_text : function(param,text4translation){
            let txt = '-'
            if (typeof param == 'string' && param != '' || (typeof param == 'number' && param == 1) || (typeof param == 'object' && param && param.length > 0)) {
               txt = this.$t(text4translation)
            }
            return txt
        },
        atu_motif : function(param) { 
            const temp = [];
            param.forEach((val) => {
                let txt = '';
                txt += val.motif ? "<p>"+ this.$t("atu_motif."+val.motif)+"</p>":""
                txt += val.date_start ? "<p>"+ this.DDMMMYY(val.date_start)+"</p>":""  
                if(txt){
                    temp.push(txt)
                }                
            });
            const return_txt = this.check_empty(temp.join('<br>'));
            return return_txt
        }, 
        json_fields : function(param, key){ 
            let txt = ''
            if(param){   
                for(const val of param){
                    if(key == "ISP_libelle"){
                        txt += val[key] ?  "<p>"+ this.$t("isp."+val[key])+"</p>" : ""
                    }else{
                        txt += val[key] ? `<p>${val[key]}</p>` : ""
                    }                    
                }       
            }
            txt = this.check_empty(txt)
            return txt
        },    
        transparence_hta : function(param){
            let html = ''
            let hta_process = param['hta_decision']
            let array = ['Recommended', 'Recommended with limitations', 'Not recommended']                                                             
            if(array.includes(param['prioritis_hta_index'])){
                hta_process = 'transparence_hta_' + 'Positive' + "_" + param['prioritis_hta_index']
            }
            if(hta_process){
                html = '<span class="edit-default">'
                html += "<span class=\"" + this.color_type_limitation_2(param['hta_decision'], param['prioritis_hta_index'])+"\">"+ (hta_process ? this.$t(hta_process) : "")+"</span>"
                html += '</span>'
            }
            html = this.check_empty(html)
            return html
        },
        max_row(param){
            const temp = []
            temp.push(1)
            if(this.indication_jo_info[param.drug_id]){
                temp.push(this.indication_jo_info[param.drug_id].length)
            }
            if(param.transparence && param.transparence.atu_france){
                temp.push(param.transparence.atu_france.length)
            }
            if(param.transparence && param.transparence.transparence_smr){
                temp.push(param.transparence.transparence_smr.length)
            }

            return Math.max(...temp)
        },
        getResults(page = 1) {
            console.log(page)
        },
        modifyPage
    },
}
</script>

<style scoped>
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>
